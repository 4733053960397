import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore"; // Import Firestore functions
import { db } from "../firebase/firebaseConfig";
import { toast } from "react-toastify";

export const getUserByUID = async (uid) => {
  try {
    const userRef = doc(db, "users", uid);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();

      return userData;
    } else {
      toast.error("User not found.");
      return;
    }
  } catch (error) {
    console.error("Error fetching user::", error);
    throw new Error(error);
  }
};

export const toggleUserBlockStatus = async (userId) => {
  try {
    const userRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      let newBlockStatus = true; // Default to true if 'isBlocked' doesn't exist

      // Toggle if 'isBlocked' field already exists
      if ("isBlocked" in userData) {
        newBlockStatus = !userData.isBlocked;
      }

      // Update the 'isBlocked' field with the new value
      await updateDoc(userRef, {
        isBlocked: newBlockStatus,
      });

      toast.success(
        newBlockStatus ? "Blocked Successfully." : "Unblocked successfully."
      );
    } else {
      toast.error("User not found.");
      return;
    }
  } catch (error) {
    console.error("Error toggling block status:", error);
    throw new Error(error);
  }
};

export const getUserHospitals = async (userId) => {
  try {
    const hospitalsRef = collection(db, "hospitals"); // Reference to the hospitals collection

    // Fetch all hospitals
    const hospitalsSnapshot = await getDocs(hospitalsRef);

    // Create an array to store all hospitals the user is registered in
    let userHospitals = [];

    // Loop through all hospitals and check if the user is in any hospital's users array
    if (!hospitalsSnapshot.empty) {
      // Use map instead of forEach for async operations
      await Promise.all(
        hospitalsSnapshot.docs.map(async (item) => {
          const hospitalData = item.data();
          const users = hospitalData.users || []; // Get the users array for each hospital

          // Check if the user exists in the users array
          const userInHospital = users.some((user) => user.userId === userId);

          if (userInHospital) {
            let currentHospital = null;
            const hospitalDoc = await getDoc(doc(db, "users", item.id));
            if (hospitalDoc.exists()) {
              currentHospital = hospitalDoc.data();
            }

            // If the user is found, store the hospital data
            const hospitalRow = {
              id: item.id,
              name: currentHospital?.name || "",
              location: currentHospital?.location || "",
              phoneNumber: currentHospital?.phoneNumber || "",
              email: currentHospital?.email || "",
              doctorsCount: users.filter((user) => user.role === "doctor")
                .length,
              nursesCount: users.filter((user) => user.role === "nurse").length,
              patientsCount: users.filter((user) => user.role === "patient")
                .length,
            };

            userHospitals.push(hospitalRow); // Add the hospital row to the array
          }
        })
      );
    }

    // Return all hospitals where the user is registered
    return userHospitals;
  } catch (error) {
    console.error("Error finding user's hospitals:", error);
    throw new Error(error);
  }
};
