import { Box, IconButton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { customColors } from "../../assets/styles/Theme";
import { AuthContext } from "../../contexts/Auth.context";
import {
  BadgeOutlined,
  Groups2Outlined,
  LocalHospitalOutlined,
  NotificationsOutlined,
  VaccinesOutlined,
} from "@mui/icons-material";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { getAllDoctors } from "../../utils/functions/doctors";
import { getAllNurses } from "../../utils/functions/nurses";
import { getAllPatients } from "../../utils/functions/patients";
import TotalCards from "../cards/TotalCards";
import { getAllHospitals } from "../../utils/functions/hospitals";

const AdminWidget = () => {
  const { userData } = useContext(AuthContext);
  const { setActiveBottomNavText, setTopLabel } =
    useContext(ActiveSidebarContext);

  const [doctors, setDoctors] = useState([]);
  const [nurses, setNurses] = useState([]);
  const [patients, setPatients] = useState([]);
  const [hospitals, setHospitals] = useState([]);

  useEffect(() => {
    setTopLabel("Dashboard");
    (async () => {
      const { doctorsData } = await getAllDoctors();
      setDoctors(doctorsData);

      const { nursesData } = await getAllNurses();
      setNurses(nursesData);

      const { patientsData } = await getAllPatients();
      setPatients(patientsData);

      const { hospitalsData } = await getAllHospitals();
      setHospitals(hospitalsData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setActiveBottomNavText]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: customColors.color_primary,
            fontFamily: "Poppins",
            fontWeight: "bold",
          }}
        >
          {`Hi ${userData?.name}`}
        </Typography>

        <IconButton>
          <NotificationsOutlined
            sx={{ color: customColors.color_primary, fontSize: "1.75rem" }}
          />
        </IconButton>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <TotalCards
          icon={
            <VaccinesOutlined
              sx={{ fontSize: "4.5rem", color: customColors.color_gray }}
            />
          }
          amount={doctors?.length}
          label={"Doctors"}
          redirect={"/all-doctors"}
        />
        <TotalCards
          icon={
            <BadgeOutlined
              sx={{ fontSize: "4.5rem", color: customColors.color_gray }}
            />
          }
          amount={nurses?.length}
          label={"Nurses"}
          redirect={"/all-nurses"}
        />
        <TotalCards
          icon={
            <Groups2Outlined
              sx={{ fontSize: "4.5rem", color: customColors.color_gray }}
            />
          }
          amount={patients?.length}
          label={"Patients"}
          redirect={"/all-patients"}
        />
        <TotalCards
          icon={
            <LocalHospitalOutlined
              sx={{ fontSize: "4.5rem", color: customColors.color_gray }}
            />
          }
          amount={hospitals?.length}
          label={"Hospitals"}
          redirect={"/admin/hospitals"}
        />
      </Box>
    </>
  );
};

export default AdminWidget;
