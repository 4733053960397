import {
  ChevronLeft,
  HomeWorkOutlined,
  LogoutOutlined,
  NotificationsOutlined,
  PendingActionsOutlined,
  PublicOutlined,
} from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { customColors } from "../../assets/styles/Theme";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import AuthNavbar from "../menu/AuthNavbar";
import SidebarComponent from "../menu/SidebarComponent";
import BottomNavigator from "./BottomNavigator";
import { minimizeText } from "../../utils/others/text.utils";
import { grey } from "@mui/material/colors";
import { AuthContext } from "../../contexts/Auth.context";
import CustomButton, { CustomOutlinedButton } from "../others/CustomButton";
import { signOut, updateCurrentUser } from "firebase/auth";
import { auth, db } from "../../utils/firebase/firebaseConfig";
import { toast } from "react-toastify";
import { getAllHospitals } from "../../utils/functions/hospitals";
import { SmallLoader } from "../loading/Loader";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { handlePayment } from "../../utils/functions/payments";

const SidebarWrapper = ({ children }) => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const { showBottomNavigator, topLabel, collapsed } =
    useContext(ActiveSidebarContext);

  const [logoutLoading, setLogoutLoading] = useState(false);
  const [hospital, setHospital] = useState("");
  const [hospitalId, setHospitalId] = useState("");
  const [hospitalLoading, setHospitalLoading] = useState(false);
  const [allHospitals, setAllHospitals] = useState([]);
  const [joinHospLoading, setJoinHospLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setHospitalLoading(true);
      const { hospitalsData } = await getAllHospitals();
      setAllHospitals(hospitalsData);
      setHospitalLoading(false);
    })();
  }, []);

  const chatScreens =
    window.location.href.includes("/appointment-chat/") ||
    window.location.href.includes("/doctors-chat/");
  const isOnXsScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Sign out user
  const handleSignOut = () => {
    setLogoutLoading(true);
    signOut(auth)
      .then(() => {
        toast.success("Signed out successfully");
        localStorage.removeItem("uid");
        navigate("/signin");
        setLogoutLoading(false);
      })
      .catch((err) => {
        setLogoutLoading(false);
        toast.error(err?.message);
      });
  };

  const joinHospitalHandler = async () => {
    try {
      if (hospitalId?.length > 0 || hospital?.length > 0) {
        setJoinHospLoading(true);
        const hospitalRef = doc(db, "hospitals", hospitalId); // Reference to the hospital document
        const hospitalSnapshot = await getDoc(hospitalRef);

        // Object to add to hospital document
        const userObject = {
          userId: updateCurrentUser.uid,
          role: userData.role,
          accepted: false, // Default to false, modify as needed
          joiningTime: Date.now(),
          left: false,
          leftTime: null, // Null since the user has not left yet
          leavingReason: "",
        };

        if (hospitalSnapshot.exists()) {
          await updateDoc(hospitalRef, {
            users: arrayUnion(userObject), // Add the new user to the array
          });
        } else {
          await setDoc(hospitalRef, {
            users: [userObject], // Initialize with the user object inside an array
          });
        }
        setJoinHospLoading(false);
      } else {
        toast.error("Please select a hospital");
      }
    } catch (error) {
      setJoinHospLoading(false);
      toast.error(error?.message);
    }
  };

  const paymentHandler = async () => {
    try {
      setPaymentLoading(true);
      const result = await handlePayment(userData?.uid, userData);
      if (result) {
        window.location.href = result;
      }
      setPaymentLoading(false);
    } catch (error) {
      console.log("Error during payment::", error);
      toast.error(error?.message);
      setPaymentLoading(false);
    }
  };

  return (
    <>
      <AuthNavbar />
      <Box
        sx={{
          height: chatScreens ? "max-content" : "calc(max-content + 10vmax)",
          display: "flex",
          flexGrow: 1,
        }}
      >
        <SidebarComponent />
        <Box
          sx={{
            width: { xs: "100%", md: collapsed ? "95%" : "83.6%" },
            paddingX: { xs: "0.9rem", md: "1rem" },
            paddingTop: { xs: "1rem", md: "0rem" },
            textAlign: "left",
            marginBottom: chatScreens ? "0rem" : "5rem",
            backgroundColor: chatScreens ? grey[200] : customColors.color_white,
          }}
        >
          {!(
            (userData?.role === "admin" || userData?.role === "hospital") &&
            isOnXsScreen
          ) && (
            <>
              {!showBottomNavigator ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: 3,
                    marginBottom: 1,
                  }}
                >
                  <IconButton
                    color="inherit"
                    sx={{
                      ml: "0.1rem",
                      display: { md: "none", sm: "block", xs: "block" },
                      backgroundColor: customColors.color_white,
                      border: `1px solid ${customColors.color_gray}`,
                      ":hover": {
                        backgroundColor: customColors.color_light_gray,
                      },
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      color: customColors.color_gray,
                    }}
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeft />
                  </IconButton>

                  <Typography
                    variant="h5"
                    sx={{
                      my: "0.3rem",
                      color: customColors.color_primary,
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    {minimizeText(topLabel)}
                  </Typography>
                </Box>
              ) : (
                <BottomNavigator />
              )}
            </>
          )}
          {userData?.isBlocked ||
          (!userData?.active &&
            (userData?.role === "doctor" || userData?.role === "nurse")) ? (
            <>
              {userData.isBlocked && (
                <Typography variant="body2" sx={{ textAlign: "center" }}>
                  You've been blocked by System Administrators, Please contact{" "}
                  <Typography
                    component="a"
                    href="mailto:wisedoctor@itscholding.com"
                    sx={{
                      color: customColors.color_secondary,
                      fontStyle: "italic",
                    }}
                  >
                    wisedoctor@itscholding.com
                  </Typography>{" "}
                  or call{" "}
                  <Typography sx={{ color: customColors.color_secondary }}>
                    +961 3 984 517.
                  </Typography>
                </Typography>
              )}
              {!userData?.active &&
                (userData?.role === "doctor" || userData?.role === "nurse") && (
                  <>
                    <Typography variant="body2" sx={{ textAlign: "center" }}>
                      Your account is not active yet, please proceed with the
                      payment or contact your hospital. (if applicable)
                    </Typography>

                    <PlanComponent
                      price={userData?.role === "doctor" ? "$70.00" : "$30.00"}
                      discountedPrice={
                        userData?.role === "doctor" ? "$55.00" : "$10.00"
                      }
                      userData={userData}
                    />

                    <Box sx={{ my: 2 }}>
                      <CustomButton
                        label={`Pay ${
                          userData?.role === "doctor" ? "$55.00" : "$10.00"
                        }`}
                        loading={paymentLoading}
                        disabled={paymentLoading}
                        onClick={paymentHandler}
                      />
                    </Box>

                    {!userData?.withHospital && allHospitals?.length > 0 && (
                      <Box sx={{ my: 1 }}>
                        {hospitalLoading ? (
                          <SmallLoader />
                        ) : (
                          <Box>
                            <Typography variant="body1" sx={{ mb: 0.5 }}>
                              Forgot to add hospital?
                            </Typography>
                            <FormControl sx={{ width: "100%" }}>
                              <InputLabel id="demo-simple-select-helper-label">
                                Hospitals
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={hospital}
                                label="Hospitals"
                                onChange={(e) => setHospital(e.target.value)}
                              >
                                {allHospitals.map((hospital) => (
                                  <MenuItem
                                    key={hospital.id}
                                    sx={{ textTransform: "capitalize" }}
                                    value={hospital.id}
                                    onClick={() => setHospitalId(hospital?.id)}
                                  >
                                    {hospital.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                Please select a hospital from these options.
                              </FormHelperText>
                            </FormControl>

                            <CustomButton
                              label="Confirm Join"
                              loading={joinHospLoading}
                              disabled={joinHospLoading}
                              onClick={joinHospitalHandler}
                            />
                          </Box>
                        )}
                      </Box>
                    )}

                    <Typography
                      variant="body2"
                      sx={{ textAlign: "center", mt: 2 }}
                    >
                      Contact Customer Support{" "}
                      <Typography
                        component="a"
                        href="mailto:wisedoctor@itscholding.com"
                        sx={{
                          color: customColors.color_secondary,
                          fontStyle: "italic",
                        }}
                      >
                        wisedoctor@itscholding.com
                      </Typography>{" "}
                      or call{" "}
                      <Typography sx={{ color: customColors.color_secondary }}>
                        +961 3 984 517.
                      </Typography>
                    </Typography>

                    <CustomOutlinedButton
                      color={customColors.color_primary}
                      loading={logoutLoading}
                      disabled={logoutLoading}
                      endIcon={<LogoutOutlined />}
                      onClick={handleSignOut}
                      label="Logout"
                    />
                  </>
                )}
            </>
          ) : (
            <>
              {(userData?.role === "admin" || userData?.role === "hospital") &&
              isOnXsScreen ? (
                <Box
                  sx={{
                    height: "85vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "justify",
                    flexDirection: "column",
                    padding: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: customColors.color_red, my: "2rem" }}
                  >
                    This page is optimized for larger screens. Please access
                    this content using a desktop, laptop, or tablet for the best
                    experience.
                  </Typography>
                  <CustomOutlinedButton
                    color={customColors.color_primary}
                    loading={logoutLoading}
                    disabled={logoutLoading}
                    endIcon={<LogoutOutlined />}
                    onClick={handleSignOut}
                    label="Logout"
                  />
                </Box>
              ) : (
                <>{children}</>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const PlanComponent = ({ price, discountedPrice, userData }) => {
  return (
    <Box>
      <Paper
        elevation={5}
        sx={{
          padding: 3,
          maxWidth: 400,
          margin: "auto",
          marginTop: 5,
          borderRadius: "1rem",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          Initial Plan
        </Typography>
        <Box sx={{ px: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <PendingActionsOutlined
              sx={{ color: customColors.color_primary }}
            />
            <Typography variant="h6">Automatic scheduler</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <PublicOutlined sx={{ color: customColors.color_primary }} />
            <Typography variant="h6">Field-wide online recognition</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <NotificationsOutlined sx={{ color: customColors.color_primary }} />
            <Typography variant="h6">Appointment reminder</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <HomeWorkOutlined sx={{ color: customColors.color_primary }} />
            <Typography variant="h6">Publishments available</Typography>
          </Box>
        </Box>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          And many more...
        </Typography>
      </Paper>
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6">
          Great {userData?.role === "doctor" ? "Doctor" : "Nurse"} discount:
        </Typography>
        <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
          <Typography
            variant="subtitle"
            sx={{
              color: customColors.color_gray,
              textDecoration: "line-through",
              fontSize: "0.8rem",
            }}
          >
            {price}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
            }}
          >
            {discountedPrice}/mo
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarWrapper;
