import { Avatar, Paper, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { customColors } from "../../../assets/styles/Theme";
import { AuthContext } from "../../../contexts/Auth.context";
import { getAllHospitals } from "../../../utils/functions/hospitals";
import { toggleUserBlockStatus } from "../../../utils/functions/general";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import CustomModal from "../../others/CustomModal";

const HospitalsTable = () => {
  const { fetchUserData, userData } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [hospitals, setHospitals] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [blockUnblockLoading, setBlockUnblockLoading] = useState(false);

  useEffect(() => {
    fetchUserData(localStorage.getItem("uid"));
  }, [fetchUserData]);

  useEffect(() => {
    const fetchDoctors = async () => {
      setLoading(true);
      try {
        const { hospitalsData, hospitalRows } = await getAllHospitals();
        setRows(hospitalRows);
        setHospitals(hospitalsData);
      } catch (error) {
        console.error("Error fetching doctors:", error);
      } finally {
        setLoading(false);
      }
    };

    if (userData) {
      fetchDoctors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const blockUserhandler = async (userId) => {
    try {
      setBlockUnblockLoading(true);
      await toggleUserBlockStatus(userId);
      setBlockUnblockLoading(false);
      setOpenModal(false);
    } catch (error) {
      setBlockUnblockLoading(false);
      toast.error(error.message);
    }
  };

  const showModal = (id) => {
    const currentData = hospitals.find((item) => item.id === id);
    const newArr = [
      {
        key1: "ID",
        value1: id,
      },
      {
        key1: "Blocked",
        value1: currentData?.isBlocked ? "Yes" : "No",
      },
      {
        key1: "Name",
        value1: currentData.name,
      },
      {
        key1: "Location",
        value1: currentData.location || "Not Available",
      },
      {
        key1: "Phone Number",
        value1: currentData.phoneNumber,
      },
      {
        key1: "Email",
        value1: currentData.email,
      },
    ];
    setModalData(newArr);
    setOpenModal(true);
  };

  const columns = [
    {
      field: "#",
      headerName: "Profile",
      flex: 0.3,
      renderCell: (params) => {
        return <Avatar src={params.row.avatar} />;
      },
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "location", headerName: "Location", flex: 1 },
    {
      field: "Blocked",
      headerName: "Blocked",
      flex: 0.3,
      renderCell: (params) => {
        return <span>{params?.row?.isBlocked ? "Yes" : "No"}</span>;
      },
    },
    { field: "email", headerName: "Email", flex: 0.8 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 0.6 },
    {
      field: "View",
      headerName: "View",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <LoadingButton
            sx={{
              color: customColors.color_white,
              backgroundColor: customColors.color_primary,
              "&:hover": { backgroundColor: customColors.color_primary },
            }}
            onClick={() => showModal(params.row.id)}
          >
            View
          </LoadingButton>
        );
      },
    },
  ];

  return (
    <Paper
      sx={{
        borderWidth: "1px",
        minHeight: "10vmax",
        height: "auto",
        borderRadius: "0.5rem",
        marginTop: "1rem",
        padding: "2rem 1rem",
        textAlign: "left",
        width: "100%",
      }}
    >
      {rows.length === 0 && !loading ? (
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            marginTop: "3vmax",
            marginBottom: "3vmax",
            color: customColors.color_red,
          }}
        >
          No Hospitals Present
        </Typography>
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          slots={{
            toolbar: GridToolbar,
          }}
        />
      )}
      {openModal && (
        <CustomModal
          data={modalData}
          open={openModal}
          onClose={() => setOpenModal(false)}
          showButton={userData?.role === "admin"}
          btnText={modalData?.[1]?.value1 === "Yes" ? "Unblock" : "Block"}
          onClickFunc={() => blockUserhandler(modalData?.[0]?.value1)}
          loading={blockUnblockLoading}
        />
      )}
    </Paper>
  );
};

export default HospitalsTable;
