import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const TotalCards = ({ icon, label, amount, redirect }) => {
  return (
    <Paper
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: "1rem",
        margin: "0.7rem 1rem",
        padding: "1rem 1rem",
        width: { md: "20%", xs: "100%", sm: "45%" },
        gap: 2,
        cursor: "pointer",
        textDecoration: "none",
      }}
      elevation={4}
      component={Link}
      to={redirect}
    >
      {icon}
      <Box>
        <Typography variant="h3">{amount}</Typography>
        <Typography variant="h5" sx={{ fontFamily: "Poppins" }}>
          {label}
        </Typography>
      </Box>
    </Paper>
  );
};

export default TotalCards;
